import $ from "jquery";

/*
 * Gravity Forms uses inline event handlers which are not compatible with strict CSPs.
 * This script removes the inline handlers and attaches the click and keypress handlers below.
 * These handlers are based on code copy-pasted from HTML output and adjusted to use the `id` variable.
 * This was developed with Gravity Forms 2.7.0 and may break with future updates.
 */

export default function klugeCSP() {
  console.log("CSP Kluge initialized");
  $(document).on("gform_post_render", replace_inline_gform_handlers);
}

function replace_inline_gform_handlers(event, id, currentPage) {
  console.log(
    `Kluge fix for CSP compatibility triggered on form ${id}. See source file for details.`
  );
  const $button = $(`#gform_submit_button_${id}`);
  console.log("Found submit button:", $button.length > 0);

  $button
    .prop("onclick", null)
    .off("click")
    .prop("onkeypress", null)
    .off("keypress")
    .on("click", function (e) {
      console.log("Submit button clicked");
      if (window[`gf_submitting_${id}`]) {
        console.log("Form already submitting");
        return false;
      }

      const $form = $(`#gform_${id}`);
      console.log("Found form:", $form.length > 0);

      window[`gf_submitting_${id}`] = true;
      console.log("Triggering form submit");
      $form.trigger("submit");
      return false;
    });

  // Add a submit handler to the form itself to debug
  $(`#gform_${id}`).on("submit", function (e) {
    console.log("Form submit event triggered");
  });
}

function gformOnClick(id) {
  if (window[`gf_submitting_${id}`]) {
    return false;
  }
  if (
    !$(`#gform_${id}`)[0].checkValidity ||
    $(`#gform_${id}`)[0].checkValidity()
  ) {
    window[`gf_submitting_${id}`] = true;
  }
}

function gformKeypress(event, id) {
  if (event.keyCode == 13) {
    if (window[`gf_submitting_${id}`]) {
      return false;
    }
    if (
      !jQuery(`#gform_${id}`)[0].checkValidity ||
      jQuery(`#gform_${id}`)[0].checkValidity()
    ) {
      window[`gf_submitting_${id}`] = true;
    }
    $(`#gform_${id}`).trigger("submit", [true]);
  }
}
